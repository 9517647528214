<template>
  <div>
    <h2 class="custom-title-secondary">Boleto vencido!</h2>
    <p class="custom-normal-text">
      Não identificamos o seu pagamento dentro do prazo de vencimento. Para
      prosseguir com a sua contratação, clique no botão abaixo para gerar um
      novo boleto ou alterar seu meio de pagamento.
    </p>
    <v-btn class="custom-btn-green" @click="roolbackPayment()"
      >Voltar para a página de pagamento</v-btn
    >
  </div>
</template>

<script>
import { STEP_PAYMENT_INFO } from '@state/modules/payment-info'
import { paymentMethods } from '@state/helpers'

export default {
  name: 'payment-bill-boleto-fail',
  data() {
    return {}
  },
  methods: {
    ...paymentMethods,
    roolbackPayment() {
      this.saveCompanyPaymentInfoField({
        step: STEP_PAYMENT_INFO,
        idPaymentsType: null,
        boletoLink: null,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
</style>
